import { useContext, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import Swal from 'sweetalert2';

import { CardBody } from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';

import { filesize } from 'filesize';
import { uniqueId } from 'lodash';
import _get from 'lodash/get';
import React from 'react';
import * as Yup from 'yup';
import { UserContext } from '../../../contexts/user-context';
import { formatCpfClear, phoneMask } from "../../../helpers/helpers";
import { useUserData } from '../../../hooks/entrada-segura/use-user-data';
import { createSelfie } from '../../../pages/entrada-segura/register/services/register.view.service';
import { createUser, updateUserById } from '../../../services/user/user-service';
import { User } from '../../../type/user';
import { getBase64Text, toBase64 } from '../../../utils/fileUpload';
import Textarea from '../../bootstrap/forms/Textarea';
import showNotification from '../../extras/showNotification';
import { USER_ROLES } from '../forms/business-rules/business-rules';
import { RequiredFieldMessage } from '../forms/required-field-message';
import { SubmitButton } from '../forms/submit-button-create-form';
import { USER_ROLES_LOWER } from '../token/token-activation-form/blocks/constants';
import { Upload } from '../Upload';
import './user-info.css';
import Label from '../../bootstrap/forms/Label';
import { Document } from '../../../type/document';
interface EditUserInfoFormProps {
	additionalOnSubmitControl?: any;
	userId?: number;
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
	modalRegisterFormVisible: boolean
}
export const EditUserInfoForm: React.FC<EditUserInfoFormProps> = ({
	additionalOnSubmitControl,
	userId,
	setRefreshTable,
	modalRegisterFormVisible
}) => {
	const { user } = useContext(UserContext);
	const activeUserId = user.id;
	const userHighestRole = localStorage.getItem("highest-role");

	const userData = useUserData({ userId } as any);
	const [selfie, setSelfie] = useState<any>([]);
	const createdAt = moment().toISOString();

	const formik = useFormik({
		initialValues: {
			userName: '',
			userTaxid: '',
			telephone: '',
			email: '',
			selfie: '',
			userRole: 'Guest',
			birthdate: '',
			fatherName: '',
			motherName: '',
			userDocumentId: '',
			userStatus: 'Active',
			isSuperUser: false,
			selfieSourceUrl: '',
			inquiryStatus: 'new',
			observation: '',
		},
		validationSchema: Yup.object({
			userName: Yup.string()
				.required('Nome é um campo obrigatório'),
			userTaxid: Yup.string()
				.required('CPF é um campo obrigatório'),

			telephone: Yup.string()
				.required('Telefone é um campo obrigatório'),

			birthdate: Yup.date()
				.required('Data de Nascimento é um campo obrigatório'),
		}),
		enableReinitialize: true,
		onSubmit: async (values) => {
			const isSuperuser = !!(values.userRole === USER_ROLES.SYSADMIN);
			if (userId) {
				try {
					await updateUserById({
						id: userId,
						name: values.userName,
						taxid: values.userTaxid,
						telephone: values.telephone,
						email: values.email,
						selfie: values.selfie,
						role: values.userRole,
						birthdate: values.birthdate,
						fatherName: values.fatherName,
						motherName: values.motherName,
						documentId: values.userDocumentId,
						selfieUploadedAt: userData?.selfieUploadedAt,
						modifiedAt: userData?.modifiedAt,
						modifiedBy: activeUserId,
						selfieSourceUrl: userData?.selfieSourceUrl,
						status: values.userStatus,
						isSuperuser,
						createdBy: userData?.createdBy,
						createdAt: userData?.createdAt,
						observation: values.observation,
						inquiry_status: values.inquiryStatus,
					});

					updateSelfie()

					showNotification(`Usuário atualizado com sucesso!`, ``, 'success');
					setRefreshTable(true);
					formik.resetForm();
				} catch (error) {
					console.error('Error updating user: ', error);
					showNotification(`Erro ao atualizar usuário. Por favor, tente novamente.`, ``, 'danger');
				}
			}
			else {
				const createdBy = activeUserId;
				const isSuperuser = !!(values.userRole === USER_ROLES.SYSADMIN);

				await createUser({
					name: values.userName,
					taxid: values.userTaxid,
					telephone: values.telephone,
					email: values.email || null,
					selfie: values.selfie,
					role: values.userRole,
					birthdate: values.birthdate,
					fatherName: values.fatherName,
					motherName: values.motherName,
					documentId: values.userDocumentId,
					status: values.userStatus,
					isSuperuser,
					createdBy,
					createdAt,
					inquiry_status: values.inquiryStatus,
					observation: values.observation,
				}).then(async res => {
					try {
						console.log(selfie);
						if (selfie) {
							await processUploadSelfie(res.id);
						}
						formik.resetForm();
						setRefreshTable(true);
						showNotification(`Usuário criado com sucesso!`, ``, 'success');
					} catch (error) {
						showNotification(`Erro ao enviar as Imagens do usuário. Por favor, tente novamente.`, ``, 'danger');
					}
				}
				).catch(() => {
					showNotification(`Erro ao cadastrar usuário. Por favor, tente novamente.`, ``, 'danger');
				});
			}
			additionalOnSubmitControl();
		},
	});

	const updateSelfie = async () => {
		if (selfie[0].uploaded == false) {
			await processUploadSelfie(userId || 0);
		}
	}

	useEffect(() => {
		if (!_isEmpty(userData)) {
			formik.setValues({
				userName: userData?.name || '',
				userTaxid: userData?.taxid || '',
				telephone: userData?.telephone || '',
				email: userData?.email || '',
				selfie: userData?.selfie || '',
				userRole: userData?.role || 'guest',
				birthdate: userData?.birthdate || '',
				fatherName: userData?.fatherName || '',
				motherName: userData?.motherName || '',
				userDocumentId: userData?.documentId || '',
				userStatus: userData?.status || 'active',
				isSuperUser: userData?.isSuperuser || false,
				selfieSourceUrl: userData?.selfieSourceUrl || '',
				observation: userData?.observation || '',
				inquiryStatus: userData?.inquiryStatus || 'new',
			});

			if (userData)
				handleArchives(userData);
		}
	}, [userData]);

	const handleArchives = async (userData: User) => {
		const dadosSelfie = {
			id: getFileIdFromUrl(userData.selfieSourceUrl),
			url: userData?.selfieSourceUrl,
			preview: userData?.selfieSourceUrl,
			uploaded: true,
			name: getFileName(userData?.selfieSourceUrl),
			deleted: false
		}
		setSelfie([dadosSelfie]);
	}

	const getFileName = (url: string | undefined) => {
		if (url) {
			let inverseUrl = url.split('').reverse().join('');
			return inverseUrl.substring(0, inverseUrl.indexOf('/')).split('').reverse().join('');
		}
		return "";
	}

	const getFileIdFromUrl = (fileName: string | undefined) => {
		if (fileName) {
			let inverseUrl = fileName.split('').reverse().join('');
			return inverseUrl.substring(inverseUrl.indexOf('.') + 1, inverseUrl.indexOf('/')).split('').reverse().join('');
		}
		return "";
	}

	const handleSelfieUpload = (files: Array<File>) => {
		if (userHighestRole !== USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole !== USER_ROLES_LOWER.STAFF) {
			const uploadedFiles = files.map((file: any) => ({
				file,
				id: uniqueId(),
				name: file.name,
				readableSize: filesize(file.size),
				preview: URL.createObjectURL(file),
				progress: 0,
				uploaded: false,
				error: false,
				url: null,
				deleted: false,
			}))
			setSelfie(uploadedFiles);
		}
	}

	const getExtensionFile = (fileName: string) => {
		let inverseUrl = fileName.split('').reverse().join('');
		return inverseUrl.substring(0, inverseUrl.indexOf('.')).split('').reverse().join('');
	}

	const processUploadSelfie = async (userId: number) => {
		await createSelfie({
			selfie_source_url: getBase64Text(await toBase64(selfie[0].file)),
			modified_by: activeUserId,
			id: userId.toString()
		})
	}

	return (
		<CardBody>
			<div className='row'>
				<div className="alert alert-warning" role="alert">
					Para visualizar o(s) documento(s) originais FAVOR ENTRAR EM CONTATO COM A ENTRADA SEGURA!
				</div>

				<form onSubmit={formik.handleSubmit} style={{ fontSize: '15px' }}>
					<div className='row justify-content-center'>
						<label style={{ textAlign: 'center' }} className="form-label">Selfie</label>
						<Upload type='image' multiple={false} files={selfie} onUpload={handleSelfieUpload} isSelfie userId={userId!} />
					</div>
					<div className='row'>
						<div className='col-12'>
							<div className='row'>
								<div className='col'>
									<FormGroup id='userName' label='Nome: ' className='mt-2'>
										<Input
											disabled={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
											readOnly={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
											id='userName'
											onChange={formik.handleChange}
											value={formik.values.userName}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.userName')} />
									</FormGroup>
								</div>
								<div className='col'>
									<FormGroup id='userTaxid' label='CPF: ' className='mt-2'>
										<Input
											disabled={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
											readOnly={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
											id='userTaxid'
											onChange={formik.handleChange}
											value={formatCpfClear(formik.values.userTaxid)}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.userTaxid')} />
									</FormGroup>
								</div>
								<div className='col'>
									<FormGroup id='userDocumentId' label='RG: ' className='mt-2'>
										<Input
											disabled={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
											readOnly={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
											onChange={formik.handleChange}
											value={formik.values.userDocumentId}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
									</FormGroup>
								</div>
							</div>
							<div className='row'>
								<div className='col'>
									<FormGroup id='birthdate' label='Data de nascimento: ' className='mt-2'>
										<Input
											disabled={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
											readOnly={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
											id='birthdate'
											onChange={formik.handleChange}
											value={formik.values.birthdate}
											type='date'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.birthdate')} />
									</FormGroup>

								</div>
								<div className='col'>
									<FormGroup id='telephone' label='Telefone: ' className='mt-2'>
										<Input
											disabled={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
											readOnly={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
											id='telephone'
											onChange={formik.handleChange}
											value={phoneMask(formik.values.telephone)}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.telephone')} />
									</FormGroup>

								</div>
								<div className='col'>
									<FormGroup id='email' label='E-mail: ' className='mt-2'>
										<Input
											disabled={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
											readOnly={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
											onChange={formik.handleChange}
											value={formik.values.email}
											type='email'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
									</FormGroup>
								</div>
							</div>
						</div>


					</div>

					<div className='row'>
						<div className='col'>
							<FormGroup id='motherName' label='Nome da mãe: ' className='mt-2'>
								<Input
									disabled={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
									readOnly={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
									onChange={formik.handleChange}
									value={formik.values.motherName}
									type='text'
									style={{
										backgroundColor: 'transparent!important',
										borderColor: 'transparent!important',
									}}
								/>
							</FormGroup>
						</div>
						<div className='col'>
							<FormGroup id='fatherName' label='Nome do pai: ' className='mt-2'>
								<Input
									disabled={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
									readOnly={userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF}
									onChange={formik.handleChange}
									value={formik.values.fatherName}
									type='text'
									style={{
										backgroundColor: 'transparent!important',
										borderColor: 'transparent!important',
									}}
								/>
							</FormGroup>
						</div>
					</div>
					
					<FormGroup id='observation' className='mt-2 mb-2 text-info'>
						<Label className='text-info'>Observações: </Label>
						<Textarea
							className='border border-2 rounded border-info'
							id='observation'
							ariaLabel='With textarea'
							onChange={formik.handleChange}
							value={formik.values.observation}
						/>
					</FormGroup>
				</form>
			</div>
			<SubmitButton onClick={formik.handleSubmit} buttonId={'user-form-button'} />
		</CardBody>
	);
};