import { Building } from '../../../type/building';
import { HTTPBuildingResponse } from '../http-building-response';

export function parseBuilding(httpBuilding: HTTPBuildingResponse): Building {
	return {
		id: httpBuilding.id,
		name: httpBuilding.name,
		taxid: httpBuilding.taxid,
		logo: httpBuilding.logo,
		buildingType: httpBuilding.building_type,
		address: httpBuilding.address,
		addressNumber: httpBuilding.address_number,
		addressComplement: httpBuilding.address_complement,
		neighborhood: httpBuilding.neighborhood,
		city: httpBuilding.city,
		state: httpBuilding.state,
		zipcode: httpBuilding.zipcode,
		status: httpBuilding.status,
	};
}
