import './styles.ts'

import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";

import { Container, DropContainer, UploadMessage } from "./styles";
import { FileList } from "./components/FileList";
import { getBase64Text, toBase64 } from '../../../utils/fileUpload.js';
import { create } from '../../../services/document'

interface UploadProps {
  onUpload: Function;
  onDelete?: Function;
  files: any[];
  multiple: boolean;
  type: 'image' | 'file';
  isReadOnly?: boolean;
  isSelfie?: boolean;
  uploadedBy?: number;
  userId: number;
}

export const Upload = ({ files = [], onDelete, onUpload, multiple, type, isReadOnly, isSelfie, uploadedBy, userId, ...props }: UploadProps) => {
  const getAccepted = () => {
    if (type === 'image') {
      return {
        'image/*': ['.jpg', '.jpeg', '.png', '.gif']
      }
    }
    else {
      return {
        '*/*': []
      }
    }
  }

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject
  } = useDropzone({
    accept: getAccepted() as any,
    onDrop: async acceptedFiles => {
      if (isSelfie) {
        onUpload(acceptedFiles)
        return;
      }

      if (!userId) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Usuário não encontrado'
        })
        return;
      }

      const { value: { documentType, issuedAt, expiresAt } = {} } = await Swal.fire({
        title: 'Detalhes do arquivo',
        html: `
          <div style="display: flex; flex-direction: column; justify-content: space-between;">
            <span>Tipo de documento</span>
            <select id="document-type-file-upload">
              <option value="ID">Identidade</option>
              <option value="Driver License">CNH</option>
              <option value="Background Check">Certidão de Antecedentes Criminais</option>
            </select>

            <span>Data de emissão</span>
            <input id="issue-date-file-upload" placeholder="Data de emissão" type="date">

            <br />
            <span>Data de expiração</span>
            <input id="expiration-date-file-upload" placeholder="Data de expiração" type="date">
          </div>
        `,  
        focusConfirm: false,
        preConfirm: () => {
          return { 
            documentType: (document.getElementById('document-type-file-upload') as HTMLInputElement)?.value,
            issuedAt: (document.getElementById('issue-date-file-upload') as HTMLInputElement)?.value,
            expiresAt: (document.getElementById('expiration-date-file-upload') as HTMLInputElement)?.value
          }
        }
      })

      if (!documentType) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Preencha o tipo de documento'
        })
        return;
      }

      if (!issuedAt) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Preencha a data de emissão'
        })
        return;
      }

      if (!expiresAt && documentType === 'Background Check') {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Preencha a data de expiração'
        })
        return;
      }

      for (const file of acceptedFiles) {
        await create({
          document_type: documentType as any,
          user_id: userId,
          document_source_url: getBase64Text(await toBase64(file)),
          created_by: uploadedBy || 1,
          uploaded_at: new Date().toISOString(),
          document_expires_at: expiresAt ? new Date(expiresAt).toISOString() : null,
          document_issued_at: issuedAt ? new Date(issuedAt).toISOString() : null
        })
      }
    },
    multiple: multiple,
    maxFiles: 2
  });

  const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
    if (!isDragActive) {
      return <UploadMessage type="default">Arraste o arquivo ou clique aqui.</UploadMessage>
    }
    if (isDragReject) {
      return <UploadMessage type="error ">Arquivo não suportado.</UploadMessage>
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>
  }

  return (
    <Container>
      {
        !isReadOnly && (
          <DropContainer 
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} />
          </DropContainer>
        )
      }
      {
        !!files?.length && (
          <FileList files={files} onDelete={onDelete} isReadOnly={isReadOnly} isSelfie={isSelfie} />
        )
      }
    </Container>
  );
}