import _get from 'lodash/get';

import moment from 'moment';
import { getSpotById } from '../../../services/spot/spot-service';
import { createToken, updateTokenById } from '../../../services/token/token-service';
import { Token } from '../../../type/token';
import showNotification from '../../extras/showNotification';
import { AUTH_TYPES, TOKEN_STATUS } from '../token/token-activation-form/blocks/constants';
import { ENV } from '../../../services/constants';
import { createBoardingpass } from '../../../services/boarding-pass/boarding-pass-service';

interface handleTokenActivationParams {
    reasonAccess: string;
    token: Token;
    expiresAt?: string;
    issuedAt: string;
    loggedUser: number;
    role: string;
    buildingId: number;
    spotId: number;
    multipleSpotId?: string[]
    authorizationType: string;
    setShouldShowErrorAlert: Function;
    setErrorAlertMessage: Function;
}

export async function handleTokenActivation({
    reasonAccess,
    token,
    expiresAt,
    issuedAt,
    loggedUser,
    role,
    buildingId,
    spotId,
    multipleSpotId = [],
    authorizationType,
    setShouldShowErrorAlert,
    setErrorAlertMessage,
}: handleTokenActivationParams) {
    const modifiedAt = moment().toISOString(true);

    try {
        await updateTokenById({
            reasonAccess: reasonAccess,
            tokenId: token.id,
            expiresAt,
            modifiedAt,
            modifiedBy: loggedUser,
            status: TOKEN_STATUS.ACTIVE,
        });

        if (multipleSpotId.length > 0) {
            multipleSpotId.map(async (spot) => {
                const spotData = await getSpotById(Number(spot));
                const spotName = _get(spotData, 'name');

                await createToken({
                    reasonAccess: reasonAccess,
                    issuedBy: token.issuedBy,
                    issuedFor: _get(token, 'issuedFor'),
                    createdBy: loggedUser,
                    buildingId,
                    spotId: Number(spot),
                    authorizationType: authorizationType,
                    role,
                    expiresAt,
                    issuedAt,
                }).then(async (response) => {
                    showNotification(`Acesso ativado para o Lote ${spotName}!`, "", "success")

                    if (ENV === "demo") {
                        await createBoardingpass({
                            tokenId: response.id,
                            createdBy: loggedUser
                        }).then(() => {
                            showNotification(`Autorização de acesso enviado com sucesso!`, "", "success")
                        }).catch((e) => {
                            console.error("Ocorreu um erro ao enviar o BoardingPass", e)

                            showNotification(`Ocorreu um erro ao enviar a autorização de acesso!`, "", "danger")
                        })
                    }
                }).catch((e) => {
                    console.error("Ocorreu um erro ao criar o Token", e)

                    showNotification(`Ocorreu um erro ao ativar o acesso para o Lote ${spotName}!`, "", "danger")
                });
            })
        } else {
            if (reasonAccess === "service" || reasonAccess === "domestic_service") {
                await createToken({
                    reasonAccess: reasonAccess,
                    issuedBy: token.issuedBy,
                    issuedFor: _get(token, 'issuedFor'),
                    createdBy: loggedUser,
                    buildingId,
                    spotId,
                    authorizationType: AUTH_TYPES.QUALIFY,
                    role,
                    status: ENV === "demo" ? TOKEN_STATUS.INACTIVE : TOKEN_STATUS.ACTIVE,
                    expiresAt,
                    issuedAt,
                }).then(async (response) => {
                    showNotification(`Qualificação de acesso criada!`, "", "success")

                    await createToken({
                        reasonAccess: reasonAccess,
                        issuedBy: token.issuedBy,
                        issuedFor: _get(token, 'issuedFor'),
                        createdBy: loggedUser,
                        buildingId,
                        spotId,
                        authorizationType: authorizationType,
                        role,
                        status: ENV === "demo" ? TOKEN_STATUS.ACTIVE : TOKEN_STATUS.PENDING,
                        expiresAt,
                        issuedAt,
                    }).then(async (response) => {
                        showNotification(`Acesso criado com sucesso, porém aguardando qualificação!`, "", "success")

                        if (ENV === "demo") {
                            await createBoardingpass({
                                tokenId: response.id,
                                createdBy: loggedUser
                            }).then(() => {
                                showNotification(`Autorização de acesso enviado com sucesso!`, "", "success")
                            }).catch((e) => {
                                console.error("Ocorreu um erro ao enviar o BoardingPass", e)

                                showNotification(`Ocorreu um erro ao enviar a autorização de acesso!`, "", "danger")
                            })
                        }
                    }).catch((e) => {
                        console.error("Ocorreu um erro ao criar o Token", e)

                        showNotification(`Ocorreu um erro ao criar o acesso!`, "", "danger")
                    })
                }).catch(() => {
                    console.error("Ocorreu um erro ao criar a qualificação de acesso")

                    showNotification(`Ocorreu um erro ao criar a qualificação de acesso!`, "", "danger")
                })
            } else {
                await createToken({
                    reasonAccess: reasonAccess,
                    issuedBy: token.issuedBy,
                    issuedFor: _get(token, 'issuedFor'),
                    createdBy: loggedUser,
                    buildingId,
                    spotId,
                    authorizationType: authorizationType,
                    role,
                    expiresAt,
                    issuedAt,
                }).then(async (response) => {
                    showNotification(`Acesso ativado com sucesso!`, "", "success")

                    if (ENV === "demo") {
                        await createBoardingpass({
                            tokenId: response.id,
                            createdBy: loggedUser
                        }).then(() => {
                            showNotification(`Autorização de acesso enviado com sucesso!`, "", "success")
                        }).catch((e) => {
                            console.error("Ocorreu um erro ao enviar o BoardingPass", e)

                            showNotification(`Ocorreu um erro ao enviar a autorização de acesso!`, "", "danger")
                        })
                    }
                }).catch((e) => {
                    console.error("Ocorreu um erro ao criar o Token", e)

                    showNotification(`Ocorreu um erro ao ativar o acesso!`, "", "danger")
                })
            }
        }

        return;
    } catch (error) {
        console.error("Ocorreu um erro ao ativar o token, revertendo o token para pending", error);

        await updateTokenById({
            reasonAccess: reasonAccess,
            tokenId: token.id,
            expiresAt,
            modifiedAt,
            modifiedBy: loggedUser,
            status: TOKEN_STATUS.PENDING,
        });

        setErrorAlertMessage(`Erro durante a ativação do token!`);
        setShouldShowErrorAlert(true);

        return;
    }
}