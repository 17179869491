import React, { useEffect, useState } from "react";
import Button, { ButtonGroup } from "../../../../../../components/bootstrap/Button";
import { TableColumn } from "../../../../../../components/entrada-segura/consult-components/table-components/table-column";
import useDarkMode from "../../../../../../hooks/useDarkMode";
import DefaultAvatar from '../../../../../../assets/img/wanna/wanna3.png';
import classNames from "classnames";
import Swal from "sweetalert2";
import Avatar from "../../../../../../components/Avatar";
import { getUserSelfieById } from "../../../../../../services/user/user-service";
import { User } from "../../../../../../type/user";
import { formatCpfClear } from "../../../../../../helpers/helpers";

interface EditUserFormProps {
    user: User;
    setShouldOpenEditUserForm: any;
    refreshTable: any;
}

export const TableRow = ({ user, setShouldOpenEditUserForm, refreshTable }: EditUserFormProps) => {
    const { darkModeStatus } = useDarkMode();

    const [avatar, setAvatar] = useState<string>(DefaultAvatar);

    useEffect(() => {
        if (refreshTable)
            getSelfieTimeOut();
        else {
            getUserSelfieById(user.id, { setAvatar }, user.selfieSourceUrl)
        }
    }, [user.id, refreshTable]);

    const getSelfieTimeOut = () => {
        setTimeout(async () => {
            await getUserSelfieById(user.id, { setAvatar }, user.selfieSourceUrl)
        }, 2000);
    }
    async function openAvatarModal(avatar: string) {
        Swal.fire({
            title: 'Selfie do usuário',
            html:
                `<img src="${avatar}" style="display: block; !important; margin-left: auto !important; margin-right: auto !important; max-width: 300px !important; max-height: 500px !important;" />`,
            showDenyButton: false,
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
            backdrop: true
        });
    }

    return (
        <>
            <tr key={user.id}>
                <TableColumn>
                    <ButtonGroup>
                        <Button
                            isOutline={!darkModeStatus}
                            color='dark'
                            isLight={darkModeStatus}
                            className={classNames('text-nowrap', {
                                'border-light': !darkModeStatus,
                            })}
                            icon='Edit'
                            style={{ marginRight: '5px' }}
                            onClick={() => setShouldOpenEditUserForm(user.id)}
                            title={"Editar"}
                        />
                    </ButtonGroup>
                </TableColumn>
                <TableColumn>
                    {<Avatar title={"Clique para visualizar a selfie"} style={{ cursor: 'pointer' }} onClick={() => openAvatarModal(avatar)} src={avatar} srcSet={avatar} color={'success'} size={40} />}
                    <span className='ms-3 d-flex align-items-center text-nowrap'>
                        {user.name}
                    </span>
                </TableColumn>
                <TableColumn>{formatCpfClear(user.taxid)}</TableColumn>
            </tr>
        </>
    )
}